<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Reportes
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podrá visualizar y generar los reportes
              disciplinarios.</span
            >
          </h3>
        </div>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body min-h-70">
        <v-row v-if="reportsWithAccess.length">
          <v-col
            cols="12"
            v-for="(item, index) in reportsWithAccess"
            :key="index"
          >
            <ReportListItem
              :title="item.title"
              :route="item.route"
            ></ReportListItem>
          </v-col>
        </v-row>
        <v-row
          v-else
          class="min-h-70 rounded-lg grey lighten-5 mx-2"
          align-content="center"
          justify="center"
        >
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-icon color="blue darken-1" x-large>
              mdi-file-alert
            </v-icon>
          </v-col>
          <v-col
            class="text-subtitle-1 text-center font-weight-medium"
            cols="12"
          >
            Actualmente no tienes acceso o permisos para generar ningún reporte
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- end::Card -->
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import ReportListItem from "@/components/elements/reports/ReportListItem.vue";

export default {
  name: "DisciplineReports",
  title: "Reportes disciplinarios | GE ITR",
  components: {
    ReportListItem,
  },
  data() {
    return {
      reports: [
        {
          title: "Reporte por códigos a mejorar",
          route: "improve_code_report",
          permission: "Generar reporte de códigos a mejorar",
        },
        {
          title: "Reporte de inasistencias",
          route: "absences_report",
          permission: "Generar reporte de inasistencias",
        },
        {
          title: "Reporte de llegadas tardes",
          route: "late_arrival_report",
          permission: "Generar reporte de llegadas tardes",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Reportes disciplinarios", route: "discipline_reports" },
    ]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Reportes de disciplina");
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
    reportsWithAccess() {
      // Se retornan los que la propiedad permission se encuentre en el array de los permisos de la página actual
      return this.reports.filter((item) =>
        this.existInArray(item.permission, this.currentPageActions)
      );
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>

<style scoped>
.min-h-70 {
  min-height: 70vh;
}

.fill-height {
  min-height: 100% !important;
}
</style>
