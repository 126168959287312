<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :elevation="hover ? 16 : 2"
      :class="{
        'on-hover': hover,
      }"
      @click="goToReport"
    >
      <v-card-text class=" d-flex flex-row justify-space-between align-center">
        <div
          :class="{
            'text-h4 font-weight-medium': true,
            'text-decoration-underline text--primary': hover,
          }"
        >
          {{ title }}
        </div>
        <v-icon :color="hover ? 'primary' : 'black'" large>
          mdi-arrow-right-circle
        </v-icon>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "ReportListItem",
  props: {
    title: {
      type: String,
    },
    route: {
      type: String,
    },
  },
  methods: {
    goToReport() {
      this.$router.push({
        path: this.route,
      });
    },
  },
};
</script>
